(function () {
    'use strict';

    angular.module('informaApp')
        .component('multilevelDropdown', {
            templateUrl: 'components/multilevel-dropdown/template.html',
            controller: MultilevelDropdown,
            bindings: {
                source: '=',
                activeName: '=',
                onListClick: '<'
            }
        });

    function MultilevelDropdown($element) {
        this.$onInit = () => {
            this.subMenuElement = $element.find('.sub-menu');

            $element[0].ownerDocument.addEventListener('click', () => {
                hideSubMenu(this.subMenuElement);
            });
        };

        this.onHover = (e, item) => {
            this.activeItem = item;

            if (item.subData && item.subData.length) {
                const {offsetWidth, offsetTop} = e.currentTarget;
                const top = offsetTop - e.currentTarget.parentElement.scrollTop;
                
                showSubMenu(this.subMenuElement, offsetWidth + 1, top);
            } else {
                hideSubMenu(this.subMenuElement);
            }
        };
    }

    function showSubMenu(subMenuElement, left, top) {
        subMenuElement.css('display', 'block')
            .css('margin-left', left)
            .css('margin-top', top);
    }

    function hideSubMenu(subMenuElement) {
        subMenuElement.css('display', 'none');
    }
})();
